<template>
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card flat>
      <Async :loading="isFetching">
        <v-card-title class="flex-center">設定</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              rounded
              label="單筆最小金額"
              v-model.number="form.single_min_amt"
              :readonly="isCanEdit"
              type="number"
              outlined
            ></v-text-field>

            <v-text-field
              rounded
              label="單筆最大金額"
              v-model.number="form.single_max_amt"
              :readonly="isCanEdit"
              type="number"
              outlined
            ></v-text-field>

            <v-text-field
              rounded
              label="當天總交易量"
              v-model.number="form.daily_max_amt"
              :readonly="isCanEdit"
              type="number"
              outlined
            ></v-text-field>
          </v-container>
        </v-card-text>

        <v-card-actions class="flex-center">
          <v-btn
            rounded
            color="primary"
            depressed
            width="320"
            height="40"
            @click="submit"
            :loading="isLoading"
            :disabled="isCanEdit"
          >
            保存
          </v-btn>
        </v-card-actions>
      </Async>
      <SizeBox height="32" />
    </v-card>
  </div>
</template>

<script>
import {
  editSystemCollectRule,
  getSystemCollectRule
} from '@/api/collectRules';

export default {
  data() {
    return {
      isLoading: false,
      isFetching: true,
      id: '',
      form: {
        single_min_amt: 0,
        single_max_amt: 0,
        daily_max_amt: 0
      }
    };
  },

  computed: {
    isCanEdit() {
      return !this.$getGrantValue('collect_rule.edit');
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.isFetching = true;
      const Rule = await getSystemCollectRule();
      if (!Rule.error) {
        this.id = Rule.id;
        Object.keys(this.form).forEach(
          (key) => (this.form[key] = Number(Rule[key]))
        );
      }
      this.isFetching = false;
    },

    async submit() {
      this.isLoading = true;
      const { error } = await editSystemCollectRule({
        id: this.id,
        ...this.form
      });
      if (!error) {
        this.$toast('編輯成功');
      } else {
        this.$toast.error('編輯失敗');
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
